import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import '../styles/YoutubeModal.css'

const YouTubeModal = props => {
    const {embedUrl, setVideoModal } = props
    const handleClose = () => {
        setVideoModal(false)
    }
    return (
        <Modal id="youtube-modal" show={setVideoModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Button className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
        </Button>
        <Modal.Body>
        <iframe width="100%" height="320" src={`https://www.youtube.com/embed/${embedUrl}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Modal.Body>
      </Modal>
    )
}

export default YouTubeModal