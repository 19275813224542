import React, { useState, useEffect } from "react"
import window from "global"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Testimonials from "../components/testimonials"
import ItemsCarousel from "react-items-carousel"
import { Jumbotron, Accordion, Card, Button } from "react-bootstrap"
import PreviewCertificateModal from "../components/PreviewCertificateModal"
import CourseForm from "../components/CourseForm"
import YoutubeModal from "../components/YoutubeModal"

export const query = graphql`
  query($slug: String!) {
    coursePageDataset(SlugURL: { eq: $slug }) {
      id
      CourseContent {
        Modules
        Name
        Description
      }
      Projects {
        Name
        Description
      }
      FAQ {
        A
        Q
        Number
      }
      CourseHighlights {
        icon
        title
      }
      CourseFeatures {
        param
        value
      }
      FeaturedYoutubeId
      Id
      LearningObjectives
      SlugURL
      Title
      BannerDescription
      LongDescription
      Tools {
        ImageUrl
      }
      SEO {
        param
        value
      }
      CertificateURL
      BrochureURL
    }
  }
`

const Course = ({ data }) => {
  const course = data.coursePageDataset

  const queryString = global.window?.location.search
  const urlParams = new URLSearchParams(queryString)

  const [utmParams, setUtmParams] = useState({
    utmSource: urlParams.get("utm_source") || "",
    utmMedium: urlParams.get("utm_medium") || "",
    utmCampaign: urlParams.get("utm_campaign") || "",
  })

  const [meta, setMeta] = useState({
    title: "",
    description: "",
    image: "",
    keywords: "",
    url: "",
    author: "",
  })

  useEffect(() => {
    {
      course.SEO &&
        course.SEO.map(item => {
          switch (item.param) {
            case "Title":
              setMeta(prevState => {
                return { ...prevState, title: item.value ? item.value : "" }
              })
              break

            case "Description":
              setMeta(prevState => {
                return {
                  ...prevState,
                  description: item.value ? item.value : "",
                }
              })
              break

            case "Url":
              setMeta(prevState => {
                return { ...prevState, url: item.value ? item.value : "" }
              })
              break

            case "Image":
              setMeta(prevState => {
                return { ...prevState, image: item.value ? item.value : "" }
              })
              break

            case "Keywords":
              setMeta(prevState => {
                return {
                  ...prevState,
                  keywords: item.value ? item.value : "",
                }
              })
              break

            case "Author":
              setMeta(prevState => {
                return { ...prevState, author: item.value ? item.value : "" }
              })
              break

            default:
            // Default code
          }
        })
    }
  }, [course.SlugURL])

  const [videoModal, setVideoModal] = useState(false)

  const handleVideoModal = () => {
    setVideoModal(!videoModal)
  }

  const [showCertificate, setShowCertificate] = useState({
    show: false,
    certificateURL: course.CertificateURL || null,
    brochureURL: course.BrochureURL || null,
  })

  const [downloadSyllabusModal, setDownloadSyllabusModal] = useState(false)

  const handleDownloadSyllabus = () => {
    setDownloadSyllabusModal(true)
  }

  const [courseModalShow, setCourseModalShow] = useState(false)

  const handleCourseModal = () => {
    setCourseModalShow(!courseModalShow)
  }

  const handleCertificate = () => {
    setShowCertificate(prevState => {
      return { ...prevState, show: true }
    })
  }

  function handleClick(e) {
    var el = document.getElementById("detail" + e.currentTarget.id)
    if (el.style.display === "block") {
      el.style.display = "none"
      document.getElementById(e.currentTarget.id).innerText = "View details"
    } else {
      el.style.display = "block"
      document.getElementById(e.currentTarget.id).innerText = "Hide details"
    }
  }

  const [activeItemIndex, setActiveItemIndex] = useState(0)

  const chevronWidth = 40
  var numberOfCards = 0

  let defaultWidth

  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth
  } else {
    defaultWidth = 992
  }

  if (defaultWidth <= 570) {
    numberOfCards = 4
  } else if (defaultWidth <= 768) {
    numberOfCards = 5
  } else if (defaultWidth <= 992) {
    numberOfCards = 6
  } else {
    numberOfCards = 7
  }

  let toolsDescription;

  switch(course.SlugURL) {
    case 'full-stack-web-development-online-course':
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Full Stack Web Developer in the best way possible.`
      break;
    case 'front-end-online-course':
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Front-end Web Developer in the best way possible.`
      break;
    case 'back-end-online-course':
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Back-end Web developer in the best way possible.`
      break;
    case 'data-science-online-course':
      toolsDescription = `In this course, you'll get familiar and master the following tools and programming languages. These tools will help you deliver the job as a Data scientist in the best way possible.`
      break;
    case 'digital-marketing-offline-courses':
      toolsDescription = `In this course, you'll get familiar and master the following tools. These tools will help you deliver the job as a digital marketer in the best way possible.`
      break;
    case 'data-science-online-course':
      toolsDescription = `In this course, you'll get familiar and master the following tools. These tools will help you deliver the job as a digital marketer in the best way possible.`
      break;
    default:
      toolsDescription = `In this course, you'll get familiar and master the following tools. These tools will help you deliver the job in the best way possible.`
      break;
  }

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      image={meta.image}
      keywords={meta.keywords}
      url={meta.url}
      author={meta.author}
    >
      <Jumbotron className="mb-0 bg-white bg-lightblue">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>{course.Title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: course.BannerDescription }}
              />
              <p>
                This course, designed for both students with and without
                experience in {course.Title}, makes you a well-rounded
                professional.
              </p>
              <Button
                onClick={handleCourseModal}
                className="btn mb-4 btn-primary"
              >
                ENQUIRE NOW
              </Button>
            </div>
            <div className="col-lg-6 m-auto">
            { course.FeaturedYoutubeId &&
            <Card className="cardShadow p-0 text-center" style={{ maxWidth: "500px", margin: "auto" }}>
            <img onClick={handleVideoModal} src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" alt="youtube icon" />
            <img onClick={handleVideoModal} className="image-youtube" src="https://i.ytimg.com/vi/Wlx_SsSp1i8/maxresdefault.jpg" alt="youtube video" />
            </Card>
            }
            </div>
          </div>
        </div>
      </Jumbotron>
      <section style={{ background: "#f9fafe", padding: "20px" }}>
        <div className="container main-title course-syllabus">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mt0">Course Syllabus</h3>
            <Button onClick={handleDownloadSyllabus}>Download Brochure</Button>
          </div>
          <p>
            Modules are picked to give you a well-rounded ability in{" "}
            {course.Title} skills.
          </p>
        </div>
        <div className="container">
          <Card className="mt-4 border-0">
            <div className="row">
              {course.CourseContent &&
                course.CourseContent.map(modules => (
                  <div className="col-xl-4 mb-4">
                    <Card className="cardShadow h-100">
                      <Card.Body>
                        <Card.Title>{modules.Name}</Card.Title>
                        <Card.Text className="card-description">
                          {modules.Description}
                        </Card.Text>
                        <a
                          href="javascript:void(0)"
                          className="pl-0"
                          id={modules.Name}
                          onClick={handleClick}
                        >
                          View detail
                        </a>
                      </Card.Body>
                      <ul
                        id={"detail" + modules.Name}
                        className="collapse pl-4"
                        style={{ listStyle: "none" }}
                      >
                        {modules.Modules.map(item => (
                          <li className="p-l-0 p-t-4 p-b-4">{item}</li>
                        ))}
                      </ul>
                    </Card>
                  </div>
                ))}
            </div>
          </Card>

          <div>
            {course.Tools && (
              <div className="container tools-wrapper">
                <div className="main-title">
                  <h3 className="mt-4">
            {course.SlugURL == 'digital-marketing-offline-courses' ? `Tools you will learn` : 'Programming Languages / Tools you will learn'}
                  </h3>
                  <p>
                  {toolsDescription}
                  </p>
                </div>
                <div className="mt-4 p-4">
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={numberOfCards}
                    gutter={20}
                    leftChevron={
                      <button
                        style={{ background: "transparent", border: "none" }}
                      >
                        {"<"}
                      </button>
                    }
                    rightChevron={
                      <button
                        style={{ background: "transparent", border: "none" }}
                      >
                        {">"}
                      </button>
                    }
                    outsideChevron
                    chevronWidth={chevronWidth}
                  >
                    {course.Tools.map(item => (
                      <div>
                        <img
                          class="carousel-img"
                          src={item.ImageUrl}
                          alt="programming tool"
                        />
                      </div>
                    ))}
                  </ItemsCarousel>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section style={{ background: "#fff", padding: "20px" }}>
        <div className="container main-title">
          <h3 className="mt0">Projects</h3>
          <p>
            Each project is designed to deepen and solidify your theoretical and
            conceptual understanding.
          </p>
        </div>
        <div className="container mt-4 mb-4">
          <div className="row">
            {course.Projects &&
              course.Projects.map(modules => (
                <div className="col-xl-4 mb-4">
                  <Card className="cardShadow h-100">
                    <Card.Body>
                      <Card.Title>{modules.Name}</Card.Title>
                      <Card.Text className="card-description">
                        {modules.Description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
          <div className="alert alert-primary" role="alert">
            Due to Covid 19, we have moved all our classroom programs (in
            Malaysia, India and Singapore ) to online.
          </div>
        </div>
        
        <div className="container main-title">
          <h3 className="mt-4">Delivery Methods</h3>
          <p>
            We offer three convenient training methodologies to suit your
            individual learning styles and preferences.
          </p>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-xl-4 mb-4">
              <Card className="cardShadow">
                <Card.Img
                  variant="top"
                  className="img-course-types"
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramclass.jpg"
                />
                <Card.Body>
                  <Card.Title>Online Live Class</Card.Title>
                  <Card.Text className="card-description">
                    Access your training from the comforts of your home, college
                    or any other location you prefer. Learn with a motivated
                    group of individuals.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-xl-4 mb-4">
              <Card className="cardShadow">
                <Card.Img
                  variant="top"
                  className="img-course-types"
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramcode.jpg"
                />
                <Card.Body>
                  <Card.Title>Blended Class</Card.Title>
                  <Card.Text className="card-description">
                    Master theory and programming concepts with our recorded
                    videos and come to the learning centre for the hands-on labs
                    and project sessions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-xl-4 mb-4">
              <Card className="cardShadow">
                <Card.Img
                  variant="top"
                  className="img-course-types"
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/cramcourse/gurukul.jpg"
                />
                <Card.Body>
                  <Card.Title>Gurukul</Card.Title>
                  <Card.Text className="card-description">
                    Crampete Gurukul model is a full-time
                    classroom-cum-internship program where you learn in a
                    high-stakes development environment.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <section
        className="course-cert"
        style={{ background: "#f9fafe", padding: "20px" }}
      >
        <div className="container main-title">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mt0">Batch Details</h3>
            {/* <Button onClick={handleCertificate}>Preview Certificate</Button> */}
            {course.CertificateURL && (
              <Button onClick={handleCertificate}>Preview Certificate</Button>
            )}
          </div>
          <p>
            Flexible options that suit your learning temperament, to take where
            you want to be.
          </p>
        </div>
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-xl-4 mb-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Online Live</Card.Title>
                  <Card.Text className="card-description">
                    June 27th, 2020 <br />
                    Evening Batch (Singapore, India and Malaysia)
                    <br />
                    <br />
                    <span className="text-danger">
                      Admission Deadline: June 25th,2020
                    </span>
                    <br />
                    <br />
                    <a href="javascript:void(0)" onClick={handleCourseModal}>
                      Enrol Now & Pay Later
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-xl-4 mb-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Online Live</Card.Title>
                  <Card.Text className="card-description">
                    July 4th, 2020 <br />
                    Evening Batch (Singapore, India and Malaysia)
                    <br />
                    <br />
                    <span className="text-danger">
                      Admission Deadline: July 2nd,2020
                    </span>
                    <br />
                    <br />
                    <a href="javascript:void(0)" onClick={handleCourseModal}>
                      Enrol Now & Pay Later
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-xl-4 mb-4">
              <Card className="cardShadow">
                <Card.Body>
                  <Card.Title>Online Live</Card.Title>
                  <Card.Text className="card-description">
                    June 11th, 2020 <br />
                    Weekend Batch (Singapore, India and Malaysia)
                    <br />
                    <br />
                    <span className="text-danger">
                      Admission Deadline: July 9th,2020
                    </span>
                    <br />
                    <br />
                    <a href="javascript:void(0)" onClick={handleCourseModal}>
                      Enrol Now & Pay Later
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="alert alert-primary" role="alert">
            Due to Covid 19, we have moved all our classroom programs (in
            Malaysia, India and Singapore ) to online.
          </div>
        </div>
      </section>
      <section style={{ background: "#fff", padding: "20px" }}>
        <div className="container">
          <div className="main-title py-5">
            <h3 className="mt0">A community in the making!</h3>
            <p>
              Here's what our students have to say about being a part of the
              Crampete family and learning skills that empower them! A community
              in the making- sharing skills, experience and knowledge!
            </p>
          </div>
        </div>
        <Testimonials />
        <div className="container mt-4">
          <div dangerouslySetInnerHTML={{ __html: course.LongDescription }} />
          <Card className="border-0 mt-4">
            <h3 style={{marginBottom: "20px"}}>Frequently Asked Questions (FAQ's)</h3>
            <Accordion>
            <div itemscope="" itemtype="https://schema.org/FAQPage">
              {course.FAQ &&
                course.FAQ.map(modules => (
                  <Card itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <Card.Header itemprop="name"
                      style={{
                        padding: ".2rem 0.5rem",
                        background: "rgb(248, 249, 250)",
                      }}
                    >
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={modules.Number}
                      >
                      <h2 style={{fontSize: "1.1rem", margin: "0"}}>{modules.Q}</h2>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={modules.Number}>
                      <Card.Body itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"><p itemprop="text">{modules.A}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
            </div>
            </Accordion>
          </Card>
        </div>
      </section>
      <section className="d-none">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <Card body className="cardShadow text-center">
                <Card.Title>Download Syllabus</Card.Title>
                <Card.Text>
                  <p>
                    A course certificate helps you establish your expertise in a
                    quick and reliable fashion.
                  </p>
                  <Link
                    to="/modal/previewimage#/images/certificate/fullstack.jpg"
                    state={{ modal: true }}
                  >
                    <Button className="btn-primary p-2 mt-2 text-white rounded">
                      Download Now
                    </Button>
                  </Link>
                </Card.Text>
              </Card>
            </div>
            <div className="col-xl-4">
              <Card body className="cardShadow text-center">
                <Card.Title>Preview Certificate</Card.Title>
                <p>
                  A course certificate helps you establish your expertise in a
                  quick and reliable fashion.
                </p>
                <Link
                  to="/modal/previewimage#/images/certificate/fullstack.jpg"
                  state={{ modal: true }}
                >
                  <Button className="btn-primary p-2 mt-2 text-white rounded">
                    Click to preview
                  </Button>
                </Link>
              </Card>
            </div>
            <div className="col-xl-4">
              <Card body className="cardShadow text-center">
                <Card.Title>Preview Certificate</Card.Title>
                <p>
                  A course certificate helps you establish your expertise in a
                  quick and reliable fashion.
                </p>
                <Link
                  to="/modal/previewimage#/images/certificate/fullstack.jpg"
                  state={{ modal: true }}
                >
                  <Button className="btn-primary p-2 mt-2 text-white rounded">
                    Click to preview
                  </Button>
                </Link>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <hr className="container hrLight" />

      {courseModalShow && (
        <CourseForm utm={utmParams} course={course} setCourseModalShow={setCourseModalShow} />
      )}
      {downloadSyllabusModal && (
        <CourseForm
          brochureURL={showCertificate.brochureURL}
          course={course}
          downloadSyllabus={true}
          setDownloadSyllabusModal={setDownloadSyllabusModal}
          utm={utmParams}
        />
      )}
      {showCertificate.show && (
        <PreviewCertificateModal
          setShowCertificate={setShowCertificate}
          certificateURL={showCertificate.certificateURL}
        />
      )}
      {videoModal && <YoutubeModal setVideoModal={setVideoModal} embedUrl={course.FeaturedYoutubeId} />}
    </Layout>
  )
}

export default Course
